.header-nav_item {
    @apply mr-8 mb-0 font-thin leading-none;
    &:last-of-type {
        @apply mr-0;
    }
    a {
        @apply text-white no-underline;
    }
}

.header-menu {
    @apply font-bold border border-blue-primary p-2;
    transition: all 0.25s ease;
    @screen lg {
        @apply hidden;
    }
    &:hover {
        @apply no-underline text-white bg-gray-600;
    }
}

.header-subnav, .header-subnav-icon {
    @apply hidden;
}

.header-subnav-show {
    .header-subnav {
        @apply hidden justify-between max-w-11/12 mx-auto mt-2;
        @screen lg {
            @apply flex max-w-4xl;
        }
    }
    .header-subnav-mobile {
        @apply block;
        @screen lg {
            @apply hidden;
        }
    }
    .header-subnav-icon {
        @apply block w-4 h-4 mx-auto text-blue-primary fill-current;
    }
}

.header-subnav-item {
    @apply block px-4 py-2 text-white bg-blue-nuvista-dark shadow;
    &:hover {
        @apply text-white no-underline shadow-lg bg-blue-primary;
        transition: all 0.25s ease;
    }
}

.header_mobile-open {
    ul {
        @apply block mt-6 text-center text-lg;
    }
    a.header-menu {
        &:hover, &:focus, &:active {
            @apply no-underline text-white;
        }
    }
    .header-nav_item {
        @apply mr-0 border-b border-gray-600 py-2 leading-normal;
        transition: all 0.25s ease-in-out;
        &:hover {
            @apply bg-gray-800;
        }
        &:last-of-type {
            @apply border-0;
        }
        a {
            @apply block w-full;
        }
    }
}

;@import "sass-embedded-legacy-load-done:10";