.team-member-box {
    @apply w-full h-80 max-w-80 mb-8 bg-cover bg-center text-center shadow bg-white;
    @screen sm {
        @apply w-2/5;
    }
    @screen md {
        @apply mr-8;
    }
    @screen lg {
        @apply w-80;
    }
}

;@import "sass-embedded-legacy-load-done:14";