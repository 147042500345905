.testimonial-featured {
    @apply max-w-11/12 mx-auto my-16;
    @screen md {
        @apply flex justify-center items-center max-w-2xl;
    }
    @screen lg {
        @apply max-w-4xl;
    }
    @screen xl {
        @apply max-w-6xl;
    }
    .testimonial_featured-image {
        @apply order-1;
        @screen md {
            @apply -mr-24;
        }
        img {
            @apply mx-auto;
        }
    }
    .testimonial_featured-testimonial {
        @apply order-2 p-12 bg-gray-200;
        @screen md {
            @apply relative w-2/3 pl-32 z-10;
        }
    }
    &:nth-of-type(2n) {
        .testimonial_featured-image {
            @apply order-2;
            @screen md {
                @apply mr-0 -ml-24;
            }
        }
        .testimonial_featured-testimonial {
            @apply order-1;
            @screen md {
                @apply pl-8 pr-32;
            }
        }
    }
}

.testimonial-quote {
    @apply absolute left-0 top-0 w-16 h-16 ml-8 mt-6 mr-2 text-blue-700 fill-current opacity-25;
}

;@import "sass-embedded-legacy-load-done:11";