button, .button {
    @apply font-display py-4 px-6 bg-blue-primary border border-blue-primary text-white tracking-wider font-bold uppercase;
    transition: all 0.35s ease-in;

    &:hover {
        @apply bg-blue-nuvista-dark border-blue-nuvista-dark text-white no-underline;
    }

    &-transparent {
        @apply border-blue-primary bg-transparent hover:bg-transparent text-blue-primary hover:text-blue-500 text-lg;
    }
}

a.button {
    @apply inline-block hover:text-white hover:no-underline;
}

;@import "sass-embedded-legacy-load-done:7";