/* this file is for public and private/admin pages */

a, .button-link {
    @apply text-blue-500 underline;
    transition: color 0.25s ease;
    &:hover, &:active {
        @apply text-blue-600;
    }
}


// re-add margin to p as it's reset in tailwind's normalize
p {
    @apply my-4;
}
//re-add list styles as tailwind removes them
ul, ol {
    @apply pl-10;
}
ul {
    @apply list-disc;
}
ol {
    @apply list-decimal;
}

address {
    @apply not-italic;
}

td {
    @apply align-top;
}

;@import "sass-embedded-legacy-load-done:0";