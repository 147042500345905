.grid {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-auto-rows: minmax(22rem, auto);
    @apply justify-center max-w-sm mx-auto mb-16 px-4;
    @screen md {
        @apply max-w-md;
    }
    @screen lg {
        @apply max-w-4xl w-4/5;
    }
    @screen max {
        @apply justify-between max-w-6xl w-full;
    }
}

.grid-box {
    @apply relative w-full mx-auto shadow bg-white;
    transition: all 0.25s ease-out;
    &:hover {
        @apply no-underline shadow-lg;
    }

    .grid-box-inner {
        transition: all 0.25s ease-in;
    }

    &-contact {
        @apply w-11/12 flex justify-center items-center mb-8 px-8 py-12 bg-blue-nuvista-dark text-white;
        @screen md {
            @apply w-full mb-0;
        }
        a:hover {
            @apply text-white;
        }
    }
}

;@import "sass-embedded-legacy-load-done:9";