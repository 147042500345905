$env: development;
@tailwind base;

@tailwind components;

@import "common/common";

// global styles on the most common elements
body {
    @apply font-sans leading-normal text-gray-800;
}

ol, ul {
    @apply my-4;
}

li {
    @apply mb-2;
    &:last-of-type {
        @apply mb-0;
    }
}

a {
    @apply no-underline;
}

//reduce margin between headings and paragraphs
p:first-of-type, h3 + p, h4 + p {
    @apply mt-0;
}

hr {
    @apply h-px max-w-4xl mx-auto mt-12 border-none;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}


.bounce {
    transition: all 0.5s ease;
    &:hover {
        transform: translateY(-0.2em);
    }
}

// common styles
@import "common/alerts",
    "common/content_alignment",
    "common/headings",
    "common/forms",
    "common/media";

// public only styles
@import
    "public/banner",
    "public/buttons",
    "public/content",
    "public/grid",
    "public/header",
    "public/testimonials",
    "public/wp_editor";

// page specific
@import "public/p_index",
        "public/team";

@tailwind utilities;
