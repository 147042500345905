$mqs: ();
@mixin mq($breakpoint) {
    @if map-has-key($mqs, $breakpoint) {
        @media (min-width: #{map-get($mqs, $breakpoint)}) { @content; }
    }
    @else {
        @media (min-width: $breakpoint) { @content }
    }
}

;@import "sass-embedded-legacy-load-done:5";