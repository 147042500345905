.alert, div.wpcf7-response-output {
    @apply flex justify-between w-4/5 mt-0 mx-auto mb-1 py-2 px-4 bg-gray-200 border-l-10;

    // alerts nested inside forms should go the full width
    .form-wrap & {
        @apply w-full;
    }
}

div.wpcf7-display-none {
    @apply hidden;
}

.alert-success, div.wpcf7-mail-sent-ok {
    @apply border-green-500;
}
.alert-info {
    @apply border-blue-400;
}
.alert-warning {
    @apply border-orange-500;
}
.alert-danger {
    @apply border-red-500;
}

;@import "sass-embedded-legacy-load-done:1";