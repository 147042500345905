.w-content, .w-content-narrow, .w-content-copy, .w-content-wide {
    @apply max-w-11/12 mx-auto;
}
.w-content {
    @apply xl:max-w-6xl;
}
.w-content-narrow {
    @include mq(844px) {
        @apply max-w-3xl;
    }
}
.w-content-copy {
    @apply sm:max-w-xl;
}
.w-content-wide {
    @include mq(1405px) {
        @apply max-w-7xl;
    }
}

;@import "sass-embedded-legacy-load-done:8";